import logoDark from "@/images/logo.png";
import slideOneImage1 from "@/images/slider/slider-1.jpg";
import slideOneImage2 from "@/images/slider/slider-2.jpg";
import slideOneImage3 from "@/images/slider/slider-3.jpg";
import slideOneImage4 from "@/images/slider/slider-4.jpg";
import slideOneImage5 from "@/images/slider/slider-5.jpg";
import serviceImage1 from "@/images/services/service-1.png";
import serviceImage2 from "@/images/services/service-3.png";
import serviceImage3 from "@/images/services/service-2.png";
import funfactShape from "@/images/shape/about-shape.png";
import galleryOne1 from "@/images/gallery/gallery-1.jpg";
import galleryOne2 from "@/images/gallery/gallery-2.jpg";
import galleryOne3 from "@/images/gallery/gallery-3.jpg";
import galleryOne4 from "@/images/gallery/gallery-4.jpg";
import galleryOne5 from "@/images/gallery/gallery-5.jpg";
import galleryOne6 from "@/images/gallery/gallery-6.jpg";
import testimonial1 from "@/images/testimonial/testimonial-1.jpg";
import testimonial2 from "@/images/testimonial/testimonial-2.jpg";
import testimonial3 from "@/images/testimonial/testimonial-3.jpg";
import blog1 from "@/images/blog/blog-1.jpg";
import blog2 from "@/images/blog/blog-2.jpg";
import blog3 from "@/images/blog/blog-3.jpg";
import blog4 from "@/images/blog/blog-4.jpg";
import blog5 from "@/images/blog/blog-5.jpg";
import blog6 from "@/images/blog/blog-6.jpg";
import blog7 from "@/images/blog/blog-7.jpg";
import blog8 from "@/images/blog/blog-8.jpg";
import blog9 from "@/images/blog/blog-9.jpg";
import volet1 from "@/images/blog/volet3.jpg";
import volet3 from "@/images/blog/volet2.jpg";
import volet4 from "@/images/blog/volet4.jpg";
import volet5 from "@/images/blog/volet5.jpg";
import volet6 from "@/images/blog/volet6.jpg";
import volet7 from "@/images/blog/volet7.jpg";
import porte1 from "@/images/blog/porte1.jpg";
import porte2 from "@/images/blog/porte2.jpg";
import porte3 from "@/images/blog/porte3.jpg";
import porte4 from "@/images/blog/porte4.jpg";
import porte5 from "@/images/blog/porte5.jpg";
import porte6 from "@/images/blog/porte6.jpg";
import team1 from "@/images/team/team-1.jpg";
import team2 from "@/images/team/team-2.jpg";
import team3 from "@/images/team/team-3.jpg";
import team4 from "@/images/team/team-4.jpg";
import comment1 from "@/images/comment-1.jpg";
import comment2 from "@/images/comment-2.jpg";

export const Logo = {
  dark: logoDark
};

export const NavLinksData = [
  {
    name: "Acccueil",
    url: "/"
  },
  {
    name: "Fenetres",
    url: "/fenetre"
  },
  {
    name: "Volets",
    url: "/volet"
  },
  {
    name: "Portes",
    url: "/porte"
  },
  {
    name: "Prestations de service",
    url: "#",
    subItems: [
      
      {
        name: "Réparation express",
        url: "/service-details"
      }
    ]
  }
];

export const TopbarInfos = [
  {
    icon: "icon-placeholder",
    tagLine: "Adresse",
    text: "183-189 avenue de Choisy 75013 Paris",
    url: "#"
  },
  {
    icon: "icon-phone-call",
    tagLine: "Appeler maintenant",
    text: "+33 6 67 06 77 89",
    url: "tel:+33 6 67 06 77 89"
  },
  {
    icon: "icon-clock",
    tagLine: "Heures d'ouverture",
    text: "Lun-Sam : 9h - 18h",
    url: "#"
  }
];

export const SlideOneData = [
  {
    backgroundImage: slideOneImage1,
    title: "Spécialisée FENETRE - PORTE - VOLET CLOTURE",
    text: "DECO ALLIANCE",
    button: "CONTACT",
    url: "/contact"
  },
  {
    backgroundImage: slideOneImage2,
    title: " la rénovation et le remplacement de menuiseries",
    text: "DECO ALLIANCE",
    button: "CONTACT",
    url: "/contact"
  }
];

export const SlideTwoData = [
  {
    backgroundImage: slideOneImage4,
    title: "Heating and Air Conditioning Specialis",
    button: "GET A FREE QUOTE",
    url: "/contact"
  },
  {
    backgroundImage: slideOneImage5,
    title: "Keep your family happy this winter, and warm.",
    button: "GET A FREE QUOTE",
    url: "/contact"
  },
  {
    backgroundImage: slideOneImage4,
    title: "Heating and Air Conditioning Specialist",
    button: "GET A FREE QUOTE",
    url: "/contact"
  }
];

export const FeatureOneData = [
  {
    icon: "icon-wallet",
    title: "Aucun paiement initial"
  },
  {
    icon: "icon-diploma",
    title: "Satisfaction garantie"
  },
  {
    icon: "icon-big_clock",
    title: "Service d'urgence"
  },
  {
    icon: "icon-handshake",
    title: "une promesse forte"
  },
  {
    icon: "icon-mechanic",
    title: "8 ans d'expérience"
  }
];

export const AboutOneData = {
  title: "Spécialisée FENETRE - PORTE - VOLET CLOTURE",
  tagLine: "Bienvenue à DECCO ALLIANCE",
  content:
    "Devis Rapide et Gratuit. Contactez un expert DECCO ALLIANCE proche de chez vous ! Assurance Qualité. Eligible Crédit d’impôt. Certifié RGE. Large Choix de design. Pose et propreté. Accessible à tous. Styles: Traditionnel, Contemporain, Original.",
  button: {
    url: "/about",
    label: "PLUS INFO"
  }
};

export const ServiceOneData = {
  title: "Nos services",
  posts: [
    {
      image: serviceImage1,
      icon: "icon-snow",
      title: "Fenetres",
      text: "Découvrez nos fenêtres  et appréciez la qualité des finitions de nos menuiseries. ",
      button: {
        label: "plus info",
        url: "/fenetre"
      }
    },
    {
      image: serviceImage2,
      icon: "icon-fire",
      title: "Volets",
      text: " Découvrez nos Volets et leur qualité de finition..",
      button: {
        label: "plus info ",
        url: "/volet"
      }
    },
    {
      image: porte3,
      icon: "icon-ac",
      title: "Portes",
      text: " DECCO ALLIANCE. Large gamme alu, grand choix de personnalisations, haut niveau de finition des produits…",
      button: {
        label: "pluds info",
        url: "/porte"
      }
    }
  ]
};

export const CallToActionOneData = {
  title: "20% sur les fenêtres en pvc offre valable jusqu'au 31 décembre 2022  ",
  button: {
    url: "/contact",
    label: "OBTENEZ UN DEVIS"
  }
};

export const CallToActionTwoData = {
  left: {
    text: "Air Conditioning Services",
    title: "Cool it down"
  },
  right: {
    text: "Heating Services",
    title: "Heat it up"
  }
};

export const FunfactOneData = {
  shapeImage: funfactShape,
  title: "nombre d'intervenants",
  posts: [
    {
      count: 4320,
      text: "portes réaliser"
    },
    {
      count: 9760,
      text: "fenetres réaliser"
    },
    {
      count: 5452,
      text: "volets réaliser "
    }
  ]
};

export const GalleryOneData = [
  {
    title: "Maintenance and Repair",
    image: galleryOne1,
    url: "#"
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne2,
    url: "#"
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne3,
    url: "#"
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne4,
    url: "#"
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne5,
    url: "#"
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne6,
    url: "#"
  }
];

export const TestimonialOneData = {
  title: "Testimonials",
  posts: [
    {
      image: testimonial1,
      content:
        "This is due to their excellent service, competitive pricing and customer support . It's throughly refresing to get such a personal touch.",
      name: "Della Barnett",
      designation: "CEO"
    },
    {
      image: testimonial2,
      content:
        "This is due to their excellent service, competitive pricing and customer support . It's throughly refresing to get such a personal touch.",
      name: "Max Dunn",
      designation: "Developer"
    },
    {
      image: testimonial3,
      content:
        "This is due to their excellent service, competitive pricing and customer support . It's throughly refresing to get such a personal touch.",
      name: "Alberta Potter",
      designation: "Director"
    },
    {
      image: testimonial1,
      content:
        "This is due to their excellent service, competitive pricing and customer support . It's throughly refresing to get such a personal touch.",
      name: "Della Barnett",
      designation: "CEO"
    },
    {
      image: testimonial2,
      content:
        "This is due to their excellent service, competitive pricing and customer support . It's throughly refresing to get such a personal touch.",
      name: "Max Dunn",
      designation: "Developer"
    },
    {
      image: testimonial3,
      content:
        "This is due to their excellent service, competitive pricing and customer support . It's throughly refresing to get such a personal touch.",
      name: "Alberta Potter",
      designation: "Director"
    }
  ]
};

export const FooterBottomData = {
  social: [
    {
      icon: "fa fa-twitter",
      url: "#"
    },
    {
      icon: "fa fa-facebook-f",
      url: "#"
    },
    {
      icon: "fa fa-linkedin",
      url: "#"
    },
    {
      icon: "fa fa-youtube-play",
      url: "#"
    }
  ]
};

export const FooterInfo = [
  {
    icon: "icon-placeholder",
    tagLine: "Adresse",
    text: "183 avenue de Choisy,Paris",
    url: "#"
  },
  {
    icon: "icon-contact",
    tagLine: "Email",
    text: "decoalliance.contact@gmail.com",
    url: "decoalliance.contact@gmail.com"
  },
  {
    icon: "icon-clock",
    tagLine: "Open Hours",
    text: " Lun-Sam: 9am - 6pm",
    url: "#"
  }
];

export const FooterWidgets = {
  about: {
    title: "About Loazzne",
    text: "At Lennom, We don’t just provide resi-dential and commercial heating and air conditioning services; we make people’s lives easier. Better.",
    tagLine: "Get a Quote",
    phone: "666 888 000"
  },
  linkOne: {
    title: "Quick Links",
    menu: [
      {
        lable: "Services",
        url: "#"
      },
      {
        lable: "Request Appointment",
        url: "#"
      },
      {
        lable: "Site Map",
        url: "#"
      },
      {
        lable: "About Us",
        url: "#"
      }
    ]
  },
  linkTwo: {
    menu: [
      {
        lable: "Careers",
        url: "#"
      },
      {
        lable: "Privacy Policy",
        url: "#"
      },
      {
        lable: "Change Location",
        url: "#"
      },
      {
        lable: "Expert Tips",
        url: "#"
      },
      {
        lable: "Terms & Conditions",
        url: "#"
      }
    ]
  }
};

export const BlogOneData = {
  title: "Our latest news",
  posts: [
    {
      image: blog1,
      title: "How does a ductless heat pump work?",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    },
    {
      image: blog2,
      title: "Simple hack to improve A/C efficiency",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    },
    {
      image: blog3,
      title: "Preparing your home before vacation",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    }
  ]
};

export const fenetres_blog = {
  posts: [
    {
      image: blog1,
      title: "Fenêtres en PVC",
      text: "C’est un système universel qui s'adapte tant à l'architecture moderne de la construction de logements individuels ou multifamiliaux qu'aux rénovations.."
    },
    {
      image: blog2,
      title: "Fenêtres en bois",
      text: "Le châssis en bois Naturo d'une épaisseur de 76mm est équipé, en standard, d'une solution efficace énergétiquement."
    },
    {
      image: blog3,
      title: "Fenêtres en aluminium",
      text: "Idéal pour la fabrication de fenêtres, de portes, de sas d’entrée, de devantures et de divers types de structures. "
    },
    {
      image: blog4,
      title: "Maco Poignée à clé",
      text: "Elle est recommandée à ceux qui tiennent premièrement à la sécurité."
    },
    {
      image: blog5,
      title: "Maco standard",
      text: "Il s’agit d’une poignée en aluminium qui étoffe notre offre commercial."
    },
    {
      image: blog6,
      title: "Maco Poignée avec bouton de verrouillage",
      text: "C’est une proposition moderne qui peut remplacer la poignée à clé."
    }
    ,
    {
      image: blog7,
      title: "Aérateur Radaks",
      text: "Cet aérateur compact assure une solution parfaite permettant d’éviter les dommages dus aux moisissures."
    },
    {
      image: blog8,
      title: "Intercalaires",
      text: "assurent une excellente solution aux clients pour qui la réalisation d’économies d’énergie thermique est une priorité."
    },
    {
      image: blog9,
      title: "Cache charnières",
      text: "C'est une solution simple et esthétique. Une série de cache-fenêtres pour les fenêtres en PVC est disponible dans une large gamme de couleurs."
    }
  ]
};

export const volet_blog = {
  posts: [
    {
      image: volet1,
      title: "Volets",
      text: "C'est une excellente solution combinant la durabilité de l'aluminium au design traditionnel des volets battants."
    },
    {
      image: volet6,
      title: "Volets extérieurs",
      text: "C’est un système avec un caisson coupé selon un angle de 45 degrés avec la face extérieure. "
    },
    {
      image: volet3,
      title: "Screen",
      text: "C'est une solution pratique et esthétique, un montage sur une façade de bâtiment ou dans une baie de fenêtre."
    },
    {
      image: volet4,
      title: "Volets sous linteau",
      text: " Ce système est une solution technologiquement avancée qui convient aux bâtiments neufs et rénovés"
    },
    {
      image: volet5,
      title: "Volets monobloc",
      text: "il permet de réduire la consommation énergétique, en particulier lors des saisons les plus froides."
    },
    {
      image: volet7,
      title: "Volets battant",
      text: "nos volets battants protègent vos menuiseries contre les intempéries et la luminosité. Le bois leur confère une véritable durabilité dans le temps."
    }
  ]
};
export const porte_blog = {
  posts: [
    {
      image: porte1,
      title: "Portes en PVC",
      text: "Les portes d’entrée sont fabriquées à partir de profils en PVC de fournisseurs."
    },
    {
      image: porte2,
      title: "Portes en bois",
      text: "Fabriquée à partir des meilleurs matériaux, la porte d’entrée en bois est une solution universelle et durable pour de nombreuses maisons individuelles."
    },
    {
      image: porte3,
      title: "Portes en aluminium",
      text: "La porte d'entrée standard est munie d'une rupture de pont thermique, grâce à laquelle elle ne subit aucune déformation, même en plein soleil. "
    },
    {
      image: porte4,
      title: "Panneaux EkoLine & AluLine",
      text: " Si vous désirez créer un caractère individuel de votre porte, vous pouvez en atteindre grâce aux remplissages des portes"
    },
    {
      image: porte5,
      title: "Portes roulantes",
      text: "C'est une solution durable et économique pour optimiser l'espace dans vos allées et garages. Fabriqué à partir des meilleurs matériaux, nos systèmes de portes roulantes sont sur mesure selon vos besoins."
    },
    {
      image: porte6,
      title: "Ressorts de traction",
      text: "Fabriquées à partir des composants de qualité supérieure, nos portes sectionnelles offrent la meilleure solution de classe premium sur le marché. "
    }
  ]
};

export const BlogTwoData = {
  posts: [
    {
      image: blog1,
      title: "How does a ductless heat pump work?",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    },
    {
      image: blog2,
      title: "Simple hack to improve A/C efficiency",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    },
    {
      image: blog3,
      title: "Preparing your home before vacation",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    },
    {
      image: blog4,
      title: "Heating and cooling safety issues",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    },
    {
      image: blog5,
      title: "Don’t neglect your outdoor unit",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    },
    {
      image: blog6,
      title: "Have your system professionally inspected",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    }
  ]
};

export const VideoOneData = {
  title: "Watch how we work",
  id: "8DP4NgupAhI"
};

export const AboutTwoData = {
  caption: " 20 <span>years experience</span> ",
  blockTitle: {
    title:
      "We are trusted source of ac and heating maintenance repairs company",
    tagline: "Why choose us"
  },
  lists: [
    {
      text: " Unmatched performance, satisfaction service guarantees"
    },
    {
      text: " Home protection through our shoe covers and mats"
    },
    {
      text: " Upfront, flat rate pricing—no overtime charges"
    },
    {
      text: " 24 / 7 availability for all emergency services"
    },
    {
      text: " Fixed right promise—done right or it’s free"
    },
    {
      text: " Clear communication and updates on service arrival"
    }
  ],
  offer: {
    tagline: "10% off",
    text: "on our all services for senior citizens."
  }
};

export const TeamOneData = {
  title: "Meet The Team",
  posts: [
    {
      image: team1,
      name: "Effie Green",
      designation: "Manager",
      social: [
        {
          icon: "fa-facebook",
          url: "#"
        },
        {
          icon: "fa-twitter",
          url: "#"
        },
        {
          icon: "fa-youtube-play",
          url: "#"
        }
      ]
    },
    {
      image: team2,
      name: "Catherine Lane",
      designation: "Worker",
      social: [
        {
          icon: "fa-facebook",
          url: "#"
        },
        {
          icon: "fa-twitter",
          url: "#"
        },
        {
          icon: "fa-youtube-play",
          url: "#"
        }
      ]
    },
    {
      image: team3,
      name: "Augusta Wells",
      designation: "Developer",
      social: [
        {
          icon: "fa-facebook",
          url: "#"
        },
        {
          icon: "fa-twitter",
          url: "#"
        },
        {
          icon: "fa-youtube-play",
          url: "#"
        }
      ]
    },
    {
      image: team4,
      name: "Kyle Fuller",
      designation: "Founder",
      social: [
        {
          icon: "fa-facebook",
          url: "#"
        },
        {
          icon: "fa-twitter",
          url: "#"
        },
        {
          icon: "fa-youtube-play",
          url: "#"
        }
      ]
    }
  ]
};

export const AboutThreeData = {
  blockTitle: {
    tagline: "Our success story",
    title: "Experience quality air conditioning service in broklyn new york"
  },
  summery:
    "There are many variations of passages of lorem ipsum available, but the majorify have suffered alteration in some form by injected humour, or randmised words which don't look even slightly believable.",
  button: {
    lable: "LEARN MORE",
    url: "/about"
  },
  box: [
    {
      icon: "icon-maintenance",
      title: "We make as cool again"
    },
    {
      icon: "icon-big-ac",
      title: "Keep your business cool"
    }
  ]
};

export const AboutFourData = {
  blockTitle: {
    title: "We are trusted by more than <span>8900</span> clients",
    text1:
      "Tincidunt elit magnis nulla facilisis sagittis is maecenas. Sapien nunced amet ultrices, dolores sit ipsum velit purus aliquet, massa fringilla leo orci.",
    text2:
      "Lorem ipsum dolors sit amet elit magnis amet ultrices purusrfed aliquet. There are many varia-tions of passages of available but the majarity have suffered."
  }
};

export const BenefitOneData = {
  title: "Our benefits",
  posts: [
    {
      icon: "icon-big_clock",
      title: "Emergency Service",
      text: "There are many passages of lorem ipsum available but the majority have alteration."
    },
    {
      icon: "icon-wallet",
      title: "No Upfront Payment",
      text: "There are many passages of lorem ipsum available but the majority have alteration."
    },
    {
      icon: "icon-diploma",
      title: "Satisfaction Guarantee",
      text: "There are many passages of lorem ipsum available but the majority have alteration."
    },
    {
      icon: "icon-mechanic",
      title: "8 Years Experience",
      text: "There are many passages of lorem ipsum available but the majority have alteration."
    }
  ]
};

export const ServiceSidebarListData = [
  {
    icon: "icon-snow",
    title: "service d'hiver",
    list: [
      {
        label: "Accompagnement",
        url: "#"
      },
      {
        label: "financement",
        url: "#"
      },
      {
        label: "pose",
        url: "#"
      },
      {
        label: "Réparation",
        url: "#"
      },
      {
        label: "nous sommes là pour vous",
        url: "#"
      }
    ]
  },
  {
    icon: "icon-fire",
    title: "Service d'été",
    list: [
      {
        label: "Accompagnement",
        url: "#"
      },
      {
        label: "financement",
        url: "#"
      },
      {
        label: "pose",
        url: "#"
      },
      {
        label: "Réparation",
        url: "#"
      },
      {
        label: "nous sommes là pour vous",
        url: "#"
      }
    ]
  }
];

export const ServiceSidebarOtherData = [
  {
    icon: "icon-ac",
    title: "Autre Service",
    content: {
      icon: "icon-support",
      title: "<span >Contactez-nous </span>6 67 06 77 89"
    }
  },
  {
    icon: "icon-building",
    title: "Service commercial",
    content: {
      icon: "icon-building",
      title: "<span>Contactez-nous </span>6 67 06 77 89"
    }
  }
];

export const CommentPostData = [
  {
    name: "David Martin",
    image: comment1,
    time: "10 Nov, 2018 - 4:00 pm",
    text: "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et aliquet."
  },
  {
    name: "Jessica Brown",
    image: comment2,
    time: "10 Nov, 2018 - 4:00 pm",
    text: "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et aliquet."
  }
];
